@use '../../style/color';
@use '../../style/font';
@use '../../style/breakPoint';
@use '../../style/general';

.scroll {
   display: block;
   position: fixed;
   top: 40%;
   right: 5%;
   z-index: 1000;
 }
 
 .scroll_btn {
    display: block;
    margin: 1rem 0;
    width: 20px;
    height: 18px;
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    background-color: color.$primary-green;
    cursor: pointer;

    &:hover {
        background-color: color.$primary-blueGreen;
     }
     
     &.active {
        background-color: color.$primary-blueGreen;
     }
 }

 
 .scroll_mobile {
    display: none;
    position: fixed;
    top: 85%;
    right: 5%;
 }

 .scroll_mobile > button {
    background-color: color.$primary-white;
    border: 2px solid color.$primary-blueGreen;
    color: color.$primary-blueGreen;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: auto;
 }

 .scroll_mobile > button:hover {
    background-color: color.$primary-lightGray;
 }

 .scroll_up_img {
    width: 30px;
    height: 30px;
 }

 @media screen and (max-width: breakPoint.$breakpoint-website) {
   .scroll {
      right: 3%;
   }
}

 @media screen and (max-width: breakPoint.$breakpoint-tablet) {
   .scroll {
      right: 2%;
   }
 }

 @media screen and (max-width: breakPoint.$breakpoint-mobile) {
    .scroll {
       display: none;
    }
 
    .section_title {
       margin-bottom: 70px;
    }

    .scroll_mobile {
        display: block;
    }
 }