@use '../../style/color';
@use '../../style/font';
@use '../../style/breakPoint';
@use '../../style/general';


.flip_cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 6rem;
    padding-top: 5rem;
}

.flip_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    &:hover {
        .hexagon {
            background-color: color.$primary-blueGreen;
        }
    }
    &.active {
        .hexagon {
            background-color: color.$primary-blueGreen;
        }
    }
}


.hexagon {
    width: 180px;
    height: 180px;
    background-color: color.$primary-green;
    clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.hexagon:hover {
    background-color: color.$primary-green;
}

.horizontal_line {
    border-left: 2px solid color.$primary-gray;
    height: 70px;
    left: 50%;
    top: 0;
}

.vertical_line {
    width: 100%;
    border-top: 1px solid black;
    transform: translateY(-8px);
}

.circle {
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: color.$primary-gray;
    z-index: 1000;
}

.step {
    font-weight: 600;
}

.card_contents {
    margin-top: 1rem;
}

.card_content {
    display: none;
}

.card_content.active {
    display: block;
    animation: fade-in 2s;
}

.cardRow {
    margin: 2rem 0;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 2fr 1fr;

    .hexagon {
        background-color: color.$primary-blueGreen;
        &:hover {
            background-color: color.$primary-blueGreen;
        } 
    }

    .card_content {
        display: flex;
        flex-direction: column;
        justify-content: center;  
    }
}


.cardRow.even {
    grid-template-columns: 1fr 2fr;
}

.cardRow.even > .card_content {
    order: 1;
}

.mobile_flip_card_container {
    display: none;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


@media screen and (max-width: 1240px) {
    .flip_cards {
        grid-gap: 4rem;
    }
}

@media screen and (max-width: breakPoint.$breakpoint-tablet) {
    .flip_cards {
        grid-gap: 2rem;

        .hexagon {
            height: 125px;
            width: 125px;
            img {
                height: 60px;
            }
        }
    }
}


@media screen and (max-width: breakPoint.$breakpoint-mobile) {
    .horizontal_line, .circle, .vertical_line {
        display: none;
    }

    .flip_card_container {
        display: none;
    }

    .hexagon {
        width: 150px;
        height: 150px;
        cursor: default;
        &:hover {
            background-color: color.$primary-gray;
        }
        img {
            height: 50%;
        }
    }


    .mobile_flip_card_container {
        display: block;
    }
}


@media screen and (max-width: 500px) {
    .cardRow {
        display: flex;
        flex-direction: column;
    }

    .cardRow.even > .card_content {
        order: 0;
    }

    .cardRow {
        .hexagon {
            width: 150px;
            margin: auto;
            padding: 1rem;
        }
    }
}