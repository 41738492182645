@use '../../style/color';
@use '../../style/font';
@use '../../style/breakPoint';
@use '../../style/general';
@use '../../style/alignment';
@use '../../style/scroll';



/* MAIN CONTENT */

.intro {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.marketing_video {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    .marketing_video_wrapper {
        width: 100%;
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .play_btn {
        width: 100px;
        height: 100px;
        transform: translate(calc(50% - 50px), calc(50% - 50px));
        position: absolute;
    }

    img {
        width: 800px;
        height: 100%;
        cursor: pointer;
    }
}

.video {
    max-width: 1040px;
    width: 100%;
    height: 518px;
}

.mobility_video {
    margin-bottom: 4rem;
}

.textBox {
    max-width: 732px;
    width: 80%;
    padding: 4% 3%;
    margin-top: 82px;
    text-align: center;
    background-color: color.$primary-blueGreen;
    color: white;
}

.two_column_section {
    &.section {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 5rem;
        align-items: center;
    }
    img {
        max-width: 500px;
    }
}

.one_column_section {
    img {
        width: 100%;
    }
}


/* Simulate Section */

.section_box_title {
    width: 450px;
    height: 200px;
    color: color.$primary-black;
    position: relative;
    left: 5%;
    background-color: color.$primary-white;
}

.section_box_title_text {
    height: 100%;
    text-align: center;
}

#section_box_mobile {
    display: none;
}

/* Transform Section */

#transform_img {
    justify-self: end;
}

.solution_image {
    margin-top: 50px;
}

.sectionLine_wrapper {
    position: absolute;
    width: 100%;
    top: 1050px;
    z-index: -10;

    .sectionLines {
        display: flex;
        justify-content: center;

        img {
            max-width: 1000px;
            margin: auto;
        }
    }
    
}

.section_btn {
    padding: 1rem 1.5rem;
}

.section_btn.main {
    margin-top: 80px;
}

.main {
    #simulate {
        padding: 50px 8% 150px 8%;
    }

    #transform {
        padding: 250px 8%;
    }

    #industrySolutions {
        padding: 250px 8%;
    }
}


@media screen and (max-width: breakPoint.$breakpoint-website) {

    .section_box_title {
        width: 400px;
    }

    .sectionLine_wrapper {
        top: 1000px;
        .sectionLines {
            img {
                max-width: 850px;
                margin: auto;
            }
        }
    }

    .main {
        #intro {
            padding: 5%;
        }

        #simulate {
            padding: 10% 8%;
        }
    
        #transform {
            padding: 10% 8%;
        }
    
        #industrySolutions {
            padding: 20% 8%;
        }
    }
}

@media screen and (max-width: breakPoint.$breakpoint-tablet) {

    .two_column_section {
        &.section {
            grid-gap: 2rem;
        }
        img {
            max-width: 300px;
            width: 100%;
        }
    }

    .section_box_title {
        width: 300px;
    }

    .sectionLine_wrapper {
        top: 950px;
        .sectionLines {
            img {
                max-width: 750px;
                margin: auto;
            }
        }
    }

    .main {
        #simulate {
            padding: 80px 8%;
        }
    
        #transform {
            padding: 125px 8%;
        }
    
        #industrySolutions {
            padding: 125px 8%;
        }
    }
}

@media screen and (max-width: breakPoint.$breakpoint-tablet-sm) {

    #main_marketing {
        width: 90%;
    }

    .marketing_video {
        width: 100%;

        .marketing_video_wrapper {
            height: 300px
        }

        .play_btn {
            width: 100px;
            height: 100px;
            transform: translate(calc(50% - 50px), calc(50% - 50px));
            position: absolute;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    .section_box_title {
       width: 250px;
    }

    .sectionLine_wrapper {
        top: 950px;
        .sectionLines {
            img {
                max-width: 650px;
                margin: auto;
            }
        }
    }

    .main {
        #simulate {
            padding: 0px 8%;
        }
    
        #transform {
            padding: 150px 8%;
        }
    
        #industrySolutions {
            padding: 50px 8%;
            .middle {
                width: 80%
            }
        }
    }

}

/* Mobile */

@media screen and (max-width: breakPoint.$breakpoint-mobile) {
    .two_column_section.section {
        grid-template-columns: 1fr;
        grid-gap: 0;
    }

    #transform_img {
        display: none;
    }

    .middle {
        width: 100%;
    }

    .section_box_title {
        display:none;
    }

    #section_box_mobile {
        display: block;
    }

    .sectionLine_wrapper {
        display: none;
    }

    .main {
        #simulate,
        #transform,
        #industrySolutions {
            padding: 72px 8%;
            .middle {
                width: 100%
            }
        }
    }
}