@use '../../style/color';
@use '../../style/font';
@use '../../style/breakPoint';
@use '../../style/general';

.slider {
    width: 100%;
    max-width: 1250px;
    height: 400px;
    position: relative;
}

.slide {
    width: 100%;
    max-width: 1250px;
    height: 400px;
    position: absolute;
    display: none;

    &.active {
    display: block;
    animation: fade-in 2s;
}
}


@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.slide_container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    gap: 2rem;
}

.slide_content {
    width: 55%;

    .quote {
        font-weight: 600;
        margin-bottom: 1rem;
        font-size: font.$font-text;
    }
}

.slide_content_title {
    display: none;
}

.slide_content_image {
    width: 250px;
    height: 250px;
    img {
        width: 100%;
    }
}

.controller {
    display: flex;
    justify-content: space-evenly;
    border-bottom: 1px solid black;
    padding-bottom: 10px;
}

.control_section {
    display: block;
    position: relative;
    cursor: pointer;
    padding: 0 1rem;
    &::after {
        content: "";
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 0;
        height: 4px;
        background-color: color.$primary-green;
        transition: width 300ms;
    }

    &:hover {
        &::after {
            width: 100%;
        }
    }

    &.active {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            width: 100%;
            left: 0;
            bottom: -10px;
            height: 4px;
            background-color: color.$primary-green;
        }
    }

}


.mobile_control_section {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: color.$primary-blueGreen;
    display: none;
    cursor: pointer;

    &.active {
        background-color: color.$primary-green;
    }
}


@media screen and (max-width: breakPoint.$breakpoint-mobile) {
    .slider_container {
        display: flex;
        flex-direction: column-reverse;
    }

    .slider {
        height: 550px;
    }

    .slide_container {
        margin-top: 2rem;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        gap: 2rem;
    }

    .slide_content {
        width: 100%;
        order: 1;
        height: 100%;
    }

    .slide_content_title {
        display: block;
        align-self: start;
        text-transform: capitalize;
    }

    .slide_content_image {
        width: 250px;
        height: 250px;
        img {
            width: 100%;
            height: 100%;
        }
    }

    .controller {
        border-bottom: none;
        justify-content: center;
    }

    .control_section {
        display: none;
    }

    .mobile_control_section {
        display: block;
        order: 2;
        margin: 0 1rem;
        &.active {
            background-color: color.$primary-green;
        }
    }
}

@media screen and (max-width: breakPoint.$breakpoint-mobile-sm) {
    .slide_content_image {
        width: 200px;
        height: 200px;
        img {
            width: 100%;
            height: 100%;
        }
    }

    .slide_content .quote {
        font-size: font.$font-regular;
    }

    .slider {
        height: 600px;
    }
}