@use '../../style/color';
@use '../../style/font';
@use '../../style/breakPoint';
@use '../../style/general';
@use '../../style/alignment';
@use '../../style/scroll';

.leadership_grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 3rem;
}

.leadership_grid_item {
    display: flex;
    flex-direction: column;
    width: 100%;
    img {
        width:100%;
        height: 200px;
        object-fit: cover;
        object-position: center; 
    }

    a {
        text-decoration: underline;
        font-size: var(--font-link);
        margin-top: 20px;
        &:hover {
            color: color.$primary-blueGreen;
        }
    }
    
}


@media screen and (max-width: 1080px) {
    .leadership_grid {
        grid-template-columns: repeat(3, 1fr);
    }

}

@media screen and (max-width: 720px) {
    .leadership_grid {
        grid-template-columns: 1fr;
    }
}