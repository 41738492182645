@use 'color';
@use 'font';
@use 'breakPoint';

$section-padding: 100px 8%;


a {
    text-decoration: none;
    color: color.$primary-black;
}

.box-shadow {
   box-shadow: 3px 1px 5px 0px color.$shadow-black;
   -webkit-box-shadow: 3px 1px 5px 0px color.$shadow-black;
   -moz-box-shadow: 3px 1px 5px 0px color.$shadow-black;
}

button {
   background-color: color.$primary-blueGreen;
   color: color.$primary-white;
   border-radius: 5px;
   border: 0px solid;
   cursor: pointer;

   &:hover {
    background-color: color.$primary-green;
 }
   &:disabled {
      background-color: black;
      pointer-events: none;
   }
}

.demo_btn {
   padding: 0.6rem;
}

.submit_btn {
   padding: 1rem 1.5rem;
   font-size: font.$font-btn;
   position: absolute;
   right: 0;
}

/* Section */

.catchphrase {
   margin-top: 20px;
   font-size: font.$font-header1;
   margin-bottom: 72px;
   width: 100%;
   display: block;
   line-height: 2.5rem;
}

/* General Section */

.section {
   max-width: breakPoint.$breakpoint-website;
   padding: $section-padding;
   min-height: 200px;
   margin: auto;
}

.main_section_title {
   color: color.$primary-blueGreen;
   margin-bottom: 2rem;
}

.section_title {
   margin-bottom: 100px;
}

/* QuoteBox */
.quoteBox {
   position: relative;
}

/* Square Border  */

.borderLeft {
   border-left: 4px solid color.$primary-blueGreen;
   position: absolute;
   top: 70%;
   bottom: 0;
}

.borderTopRight {
   border-top: 4px solid color.$primary-blueGreen;
   position: absolute;
   right: 0;
   left: 80%;
}

.borderTopBottom {
   border-right: 4px solid color.$primary-blueGreen;
   position: absolute;
   top: 0;
   bottom: 70%;
   right: 0;
}

.borderBottomLeft {
   border-bottom: 4px solid color.$primary-blueGreen;
   position: absolute;
   left: 0;
   right: 80%;
   bottom:0;
}

@media screen and (max-width: breakPoint.$breakpoint-mobile) {
   #contactUs {
      grid-template-columns: 1fr;
      grid-gap: 2rem;
      margin: 0 auto;
   }

   .contactUs_img {
      order: -1;
   }

   #contactUs img {
      height: 300px;
   }

   .header_logo img {
      width: 50px;
      height: 50px;
   }

   .section {
      padding: 70px 48px;
   }

   .scroll {
      display: none;
   }

   .section_title {
      margin-bottom: 70px;
   }
}

input[type="checkbox"] {
   accent-color: color.$primary-footer-link;
}