$primary-black: #000000;
$primary-footerBlack: #011923;
$primary-darkGray: #555f61;
$primary-darkGray-opacity: #555f61bb;
$primary-gray: #a7b2b2;
$primary-lightGray: #d3d8dc;
$primary-lightGray-opacity: #d3d8dc9a;

$primary-darkBlueGreen: #012931;
$primary-cyan: #015061;
$primary-blueGreen: #014760;
$primary-blueGreen-opacity: #01476065;
$primary-blueGreen-opacity-active: #014760df;
$primary-footer-link: #0076a8;
$primary-green: #9cc442;
$primary-green-opacity: #9dc442bc;
$primary-white: #ffffff;
$primary-red: rgb(161, 0, 0);

$shadow-black: rgba(170, 170, 170, 0.75);

.blueGreen {
    color: $primary-blueGreen;
}

.white {
    color: $primary-white;
}

.darkGray {
    color: $primary-darkGray;
}

.green {
    color: $primary-green;
}