@use 'breakPoint';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@font-face {
    font-family: 'Duborics';
    src: url('../assets/font/Duborics.otf') format('opentype');
}


$font-header1: 2.2rem;
$font-header2: 2.1rem;
$font-header3: 1.8rem;
$font-header4: 1.6rem;
$font-link: 1rem;

$font-regular: 1rem;
$font-text: 1.2rem;
$font-sm-text: 0.9rem;
$font-copyright: 0.7rem;
$font-header: 0.8rem;
$font-lg-btn: 1rem;
$font-btn: 0.8rem;
$font-mobile: 1rem;

*{
    font-family: 'Open Sans', sans-serif;
}

.duborics {
    font-family: 'Duborics';
}

.header_1 {
    font-size: $font-header1;
}

.header_2 {
    font-size: $font-header2;
}

.header_3 {
    font-size: $font-header3;
    font-weight: 700;
    text-transform: uppercase;
}

.header_4 {
    font-size: $font-header4;
}

.TM {
    font-size: $font-copyright;
}

.number_section {
    margin-left: 40px;
}

.text_middle {
    text-align: center;
}

.box_title {
    display: block;
    text-transform: uppercase;
    line-height: 4rem;
    font-size: $font-header3;
    font-weight: 500;
    margin-bottom: 1.5rem;
}

.quote_box_content {
    padding: 3rem;
}

.italic {
    font-style: italic;
}

.light {
    font-weight: 300;
}

.normal {
    font-weight: 400;
}

.bold {
    font-weight: 500;
}

.text {
    font-size: $font-text;
    line-height: calc($font-text + 0.4rem);
}

.superscript {
    vertical-align: super;
    font-size: $font-copyright
}

.sm-text {
    font-size: $font-sm-text;
}

@media(max-width: breakPoint.$breakpoint-tablet) {
    $font-text: 1.1rem;
    $font-header1: 1.6rem;
    $font-header2: 1.5rem;
    $font-header3: 1.4rem;

    .header_1 {
        font-size: $font-header1;
    }

    .header_2 {
        font-size: $font-header2;
    }

    .text {
        font-size: $font-text;
        line-height: calc($font-text + 0.4rem);
    }

    .box_title {
        font-size: $font-header3;
        line-height: 3rem;
    }
}

@media(max-width: breakPoint.$breakpoint-tablet-sm) {
    $font-text: 1.0rem;
    $font-header1: 1.4rem;

    .header_1 {
        font-size: $font-header1;
    }

    .text {
        font-size: $font-text;
        line-height: calc($font-text + 0.4rem);
    }
}

@media(max-width: breakPoint.$breakpoint-mobile) {
    $font-header2: 1.4rem;
    $font-header3: 1.2rem;
    $font-header4: 1.2rem;
    $font-link: 0.9rem;
    
    $font-regular: 1rem;
    $font-text: 1rem;
    $font-sm-text: 0.9rem;
    $font-header: 0.8rem;
    $font-lg-btn: 1rem;
    $font-btn: 0.8rem;

    .number_section {
        margin-left: 20px;
    }

    .quote_box_content {
        padding: 2rem;
    }

    .box_title {
        font-size: $font-header3;
    }

    .header_1 {
        font-size: $font-header1;
    }
    
    .header_2 {
        font-size: $font-header2;
    }
    
    .header_3 {
        font-size: $font-header3;
    }

    .header_4 {
        font-size: $font-header4;
    }

    .text {
        font-size: $font-regular;
        line-height: calc($font-regular + 0.4rem);
    }
}