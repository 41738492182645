@use '../../style/color';
@use '../../style/font';
@use '../../style/breakPoint';
@use '../../style/general';

/* Contact Us */

#contactUs {
    padding: 200px 8%;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    max-width: 1240px;
    margin: auto;
 
    .contactUs_img img {
     width: 100%;
  }
 }
 

 @media screen and (max-width: breakPoint.$breakpoint-mobile) {
    .section_content {
        &.middle {
            width: 100%;
        }
        grid-template-columns: 1fr;
    }
 }