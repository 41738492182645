@use '../../style/color';
@use '../../style/font';
@use '../../style/breakPoint';
@use '../../style/general';


#contactUs_section {
    max-width: 1040px;
    padding-top: 70px;
}

.contactUs_container {
    display: grid;
    align-items:flex-start;
    grid-column-gap: 5rem;
    margin-top: 50px;
}

.contactUs_form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 4rem;
    grid-row-gap: 2rem;
    position: relative;
}

.form_input {
    display: flex;
    flex-direction: column;

    label {
        font-size: font.$font-sm-text;
        margin: 0.5rem 0;  
    }

    input,
    textarea {
        padding: 0.5rem;
        border: 2px solid color.$primary-lightGray;
        border-radius: 5px;
    }

    &.last {
        grid-column-start: 1;
        grid-column-end: 3; 
    }
}


textarea {
    overflow-y: scroll;
    height: 150px;
}

.submit_container {
    grid-column-start:2;
    grid-column-end: 3;
    position: relative;
}

.contactUs_img img {
    width: 100%;
}

.required {
    color: color.$primary-red;
    padding-left: 0.5rem;
}

.submitted {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 570px);
}

.consentForm {
    grid-column: 1/ -1
}

.consentFormBox {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 1rem;
}

.agreeBtn {
    margin-top: 0.5rem;
}

@media screen and (max-width: breakPoint.$breakpoint-tablet) {
    .contactUs_container {
        grid-template-columns: 1fr;
    }

    .contactForm_img {
        margin: auto;
        grid-row: 1;
        img {
            max-width: 300px;
        }
    }
    
    .submitted {
        height: calc(100vh - 620px);
    }
}

@media screen and (max-width: breakPoint.$breakpoint-mobile) {

    #contactUs_section {
        padding-top: 0;
    }


    .contactUs_container {
        grid-template-columns: 1fr;
    }

    .contactUs_form {
       display: flex;
       flex-direction: column;
       grid-row-gap: 1rem;
    }

    .submitted {
        height: calc(100vh - 590px);
    }

    .consentFormBox {
        display: grid;
        grid-template-columns: 100%;
        gap: 1rem;
    }
}

.underline {
    text-decoration-line: underline;
}

.contactUs_container {
    a {
        color: color.$primary-footer-link;
        cursor: pointer;
    }
}