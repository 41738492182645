@use '../../style/color';
@use '../../style/font';
@use '../../style/breakPoint';
@use '../../style/general';

.solution_categories {
    position: relative;
}

.categoriesLine_wrapper {
    position: absolute;
    margin-left: 110px;
    top: 0;
    z-index: -1;
}

.solution_category {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1rem;

    img {
        max-width: 350px;
    }
}

.solution_category:not(:last-child) {
    margin-bottom: 200px;
}

.solution_details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.solution_details > img {
    width: 360px;
    height: 360px;
}

.solution_details_content > .header_3 {
    margin-bottom: 1rem;
}

.solution_section_title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 50px;
}

.solution_box {
    background-color: color.$primary-blueGreen;
    padding: 4rem;
}

.solution_box ul {
    margin-top: 25px;
}

.solution_box li {
    padding-bottom: 1rem;
}

.solution_content_box_row {
    position: relative;
    display: grid;
    grid-template-columns: 1.6fr 1fr;
    margin-top: 100px;
}

.solution_content_box_image {
    transform: translatex(-30px) translateY(10%);
    min-width: 300px;
}

.solution_content_box_image img {
    width: 100%;
}

@media screen and (max-width: breakPoint.$breakpoint-tablet) {
    .solution_category {
        grid-gap: 2rem;
    }
}

@media screen and (max-width: breakPoint.$breakpoint-mobile) {
    .solution_category {
        grid-template-columns: 1fr;
        grid-row-gap: 2rem;
        margin-bottom: 70px;
        img {
            max-width: 350px;
            margin: auto;
        }
    }

    .categoriesLine_wrapper {
        display: none;
    }

    .solution_details .section_btn {
        margin-top: 2rem;
    }

    .solution_section_title {
        align-items: flex-start;
    }

    .solution_section_title > img {
        width: 100px;
    }

    .solution_content_box_row {
        grid-template-columns: 1fr;
        margin-top: 70px;
    }

    .solution_box {
        padding: 2rem;
    }

    .solution_content_box_image {
        display: none;
    }
}

@media screen and (max-width: breakPoint.$breakpoint-mobile-sm) {
    .solution_category {
        img {
            width: 100%;
            max-width: 100%;
        }
    }
}