@use 'breakPoint';

/* Alignment */
.centerAlign {
    display: flex;
    align-items: center;
    justify-content: center;
}

.centerLeftAlign {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.left {
    margin-left: 10%;
}

.right {
    margin-left: 50%;
}

.small {
    width: 40%;
}

.middle {
    width: 65%;
}

.middle_wide {
    width: 80%;
}

.auto {
    margin: auto;
}

@media screen and (max-width: breakPoint.$breakpoint-mobile) {
    .middle_wide {
        width: 100%;
    }
}