@use '../../style/color';
@use '../../style/font';
@use '../../style/breakPoint';
@use '../../style/general';


.footer {
    padding: 0;
    margin: 0;
    background-color: color.$primary-black;
    width: 100%;
    height: 250px;
    clip-path: polygon(0 20%, 100% 0, 100% 100%, 0% 100%)
}

.footer_container {
    display: grid;
    align-items: center;
    height: 100%;
}

.footer_content {
    display: grid;
    grid-template-columns: 30% 70%;
    max-width: 1320px;
    align-content: center;
    margin-left: auto;
    margin-right: auto;
    padding: 2rem 1rem 1rem 0;
}

.footer {
    p {
        color: white;
        font-family: 'Open Sans', sans-serif;
        font-size: font.$font-copyright;
    }
    a {
        color: color.$primary-footer-link;
    }
    a:hover {
        text-decoration: underline;
    }
}

.link_container {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1rem;

    a {
        color: white;
    }
}

@media screen and (max-width: breakPoint.$breakpoint-tablet) {
    .footer { 
        height: 300px;
    }
}

@media screen and (max-width: breakPoint.$breakpoint-mobile) {
    .footer {
        height: 400px;
        clip-path: polygon(0 12%, 100% 0, 100% 100%, 0% 100%)
    }
    .footer_content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        .footer_logo {
            margin-top: 20px;
            img {
                max-width: 200px;
            }
        }
    }

    .link_container {
        margin-bottom: 1rem;
        font-size: 12px;
    }

    .footer_logo {
        transform: translateX(-25px);
    }
}

@media screen and (max-width: breakPoint.$breakpoint-mobile-sm) {
    .footer {
        height: 100%;
        .footer_content {
            padding: 2rem 1rem;
        }
    }

    .link_container {
        margin-bottom: 1rem;
    }
}
