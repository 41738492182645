@use '../../style/color';
@use '../../style/font';
@use '../../style/breakPoint';
@use '../../style/general';

.header {
    max-width: 1320px;
    height: 120px;
    display: flex;
    justify-content: space-between; 
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding: 0 1rem;
}

 .header_logo {
    display: flex;
    align-items: center;

    img {
        width: 80px;
        height: 80px;
     }

    .header_logo_text {
        color: color.$primary-blueGreen;
        font-weight: 600;
        font-size: font.$font-header3;
        padding-left: 12px;
    }
 }


 #overlay {
    position: fixed;
    top:0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: none;
    background-color: color.$primary-darkGray-opacity; 
 }

.nav_section {
    margin-right: 2rem;
    color: color.$primary-black;
    position: relative;

    &:hover {
        color: color.$primary-blueGreen;
        transition: all 0.3s; 
    }

    &.active {
        border-bottom: 4px solid color.$primary-blueGreen; 
    }
}

.solutions_container {
    display: inline-block;

    &:hover #solutionsDropdown {
        display: block;
    }
}


/* Dropdown Menu */

#solutionsDropdown {
    display: none;
    position: absolute;
    border-radius: 0.5rem;
    background-color: color.$primary-white;
    transform: translateX(-3px);
    z-index: 10000;
}

/* Solution Section */ 

.solutionSection {
    display: block;
    padding: 1rem 2rem;

    &:hover {
        background-color: color.$primary-blueGreen-opacity;
    }
    
    &:first-of-type {
        border-top-right-radius: 0.5rem;
        border-top-left-radius: 0.5rem;
    }
    
    &:last-of-type {
        border-bottom-right-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
    }
    &.active {
        background-color: color.$primary-lightGray;
    }
}



.mobile_nav {
    display: none;
    cursor: pointer;
    height: 24px;
    width: 22px;
    padding: 0.5rem;

    &:hover {
        background-color: color.$primary-lightGray;
        border-radius: 4px;
    }
    
    & img {
        height: 24px;
        width: 22px;
    }
}

.overlay {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: color.$primary-darkGray-opacity;
    z-index: 1000;

    &.active {
        display: block;
    }
}

.mobile_nav_content {
    display: none;
    position: fixed;
    width: 300px;
    background: color.$primary-blueGreen;
    color: color.$primary-white;
    left: -300px;
    top: 0px;
    height: 100%;
    z-index: 1000;
    text-transform: uppercase;

    ul {
        margin: 0;
    }

    .wrapper {
        padding: 3rem 2rem;
        color: white;  
    }

    .menu_content {
        padding-top: 1.5rem;
        // display: grid;
        // grid-template-columns: 1fr;
        // grid-gap: 1rem;
        a {
            cursor: pointer;
            color: white;
            font-size: font.$font-mobile;
            &.active {
                font-weight: 500;
            }
        }
        a:hover {
            color: color.$primary-green; 
        }
    
        li {
            list-style: none;
            font-weight: 300;
            padding: 0.8rem 0;
            // padding-bottom: 0.7rem;
        }
    }
}


.slideIn {
    animation: slidein 1s;
    animation-fill-mode: forwards;
}

.slideOut {
    animation: slideout 1s;
}

.mobile_nav_close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 2rem;
    padding: 0.5rem;
    cursor: pointer;

    &:hover {
        background-color: color.$primary-lightGray-opacity;
        border-radius: 50%;
    }
}


@keyframes slidein {
    from {
       transform: translateX(0px);
       transition-timing-function: ease-in;
    }
    to {
        transform: translateX(300px);
        transition-timing-function: ease-out;
    }
}

@keyframes slideout {
    from {
       transform: translateX(300px);
       transition-timing-function: ease-in;
    }
    to {
        transform: translateX(0px);
        transition-timing-function: ease-out;
    }
}


@media screen and (max-width: breakPoint.$breakpoint-tablet) {
    .header_logo {  
        img {
            width: 60px;
            height: 60px;
         }
    
        .header_logo_text {
            font-size: font.$font-header4;
        }
     }
}

@media screen and (max-width: breakPoint.$breakpoint-mobile) {
    .header {
        height: 100px;
    }

    .header_nav {
        display: none;
    }

    .mobile_nav {
        display: block;
    }
    .mobile_nav_content {
        display: block;
    }

    #overlay.is-active {
        display: block;
    }
}