@use '../../style/color';
@use '../../style/font';
@use '../../style/breakPoint';
@use '../../style/general';

.section {
    padding: 100px 5%;
}

#whatWeBring > .section_content {
    display: flex;
    margin-bottom: 150px;
}

.professional_services {
    background-color: color.$primary-blueGreen;
    width: 40%;
    padding: 4rem;
    height: calc(550px - 8rem);
    transform: translateY(40px);
    .citation {
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        padding: 2rem 3rem;
        font-size: font.$font-copyright;
        line-height: 1rem;
    }
    a {
        color: color.$primary-white;
    }
}

.divider {
    position: absolute;
    width: 20px;
    height: calc(100% - 39px);
    left: 0;
    top: 39px;
    z-index:1000;
    background-color: color.$primary-green;
}

.alliance_relationships {
    background-color: color.$primary-white;
    width: 40%;
    border: 1px solid color.$primary-blueGreen;
    padding: 4rem;
    height: calc(550px - 8rem);
    transform: translateX(-20px);
    position: relative;
}

.alliance_partner {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: start;
    align-items: center;
    grid-gap: 1rem;
    transform: translateX(-20px);
}

.box_title {
    span {
        display: block;
        line-height: 2.6rem;
    }
}

.author {
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width: breakPoint.$breakpoint-tablet) {
    .alliance_partner {
        grid-template-columns: repeat(3, 1fr);
        width: 100%;
        transform: none;
        grid-gap: 1rem;
    }
}

@media screen and (max-width: breakPoint.$breakpoint-mobile) {
    #whatWeBring > .section_content {
        flex-direction: column;
        justify-content: space-between;
    }

    .professional_services {
        width: 60%;
        height: calc(500px - 8rem);

        .citation {
            padding: 3rem;
        }
    }

    .alliance_relationships {
        transform: translate(15%, 10px);
        height: calc(500px - 8rem);
        width: 60%;
    }

    .alliance_partner img {
        max-height: 40px;
        max-width: 60px;
    }

    .box_title {
        span {
            display: block;
            line-height: 2.4rem;
        }
    }

    .divider {
        width: 100%;
        height: 30px;
        transform: translateY(-40px);
    }

    .number_section_img {
        width: 100px;
    }
}


@media screen and (max-width: breakPoint.$breakpoint-mobile-sm) {
    #whatWeBring > .section_content {
        align-items: center;
    }

    .professional_services,
    .alliance_relationships {
        height: calc(600px - 8rem);
    }

    .alliance_relationships {
        transform: translate(5%, 10px);
    }
}